import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FiestaImg from "../assets/01.jpg"

const NotFoundPage = ({ data, location }) => {
  console.log(data, location);
  const recenze = data.allWpTestimonial.nodes

  return (
    <Layout location={location} title={"Reference"}>
      <Seo title="Reference" location={location} />
      <h1>Řekli o mně</h1>
      <p>Výpis recenzí</p>
      <amp-img src={FiestaImg} width={300} height={400} alt={"test"} layout="responsive" />


      {recenze && recenze.map( recenze =>
        <div>{recenze.title}</div>
      )}
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    allWpTestimonial {
      nodes {
        content
        title
      }
    }
  }
`
